<template>
  <div>
    <div class="d-flex justify-content-start align-items-center mb-3">
      <span class="title">
        <h3 style="margin-block: auto;" v-if="user_type === 'email'">{{ action_type === 'edit' ? $t('account.editEmail') : $t('account.addEmail') }}</h3>
        <h3 style="margin-block: auto;" v-if="user_type === 'phone'">{{ action_type === 'edit' ? $t('account.editPhone') : $t('account.addPhone') }}</h3>
      </span>
    </div>

    <div class="card">
      <div class="section-card">
        <a-form :form="form" @submit="handleSubmit">
          <a-col :xs="24" :sm="24" :md="12" :offset="6">
            <a-form-item v-if="user_type === 'email'">
              <label :class="$style.labelInput">{{ $t('authForm.email') }}</label>
              <a-input
                type="email"
                size="large"
                :placeholder="$t('authForm.placeholderEmail')"
                v-decorator="['email', {
                  rules: [{
                    required: true,
                    validator: (role, value, callback) => {
                      const regex = /\S+@\S+\.\S+/
                      if (!regex.test(value)) {
                        callback('Email invalid')
                      } else if (!value) {
                        callback($t('authForm.requiredEmail'))
                      } else {
                        callback()
                      }
                    },
                    message: $t('authForm.requiredEmail'),
                  }]
                }]"
              />
            </a-form-item>
            <a-form-item v-if="user_type === 'phone'">
              <label :class="$style.labelInput">{{ $t('authForm.phoneNumber') }}</label>
              <a-input
                size="large"
                v-decorator="[
                  'phone',
                  {
                    rules: [{
                      required: true,
                      message: $t('authForm.requiredPhoneNumber'),
                      pattern: new RegExp(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g, '')
                    }],
                  },
                ]"
                :placeholder="$t('authForm.phoneNumber')"
              />
            </a-form-item>
            <a-checkbox @change="hanldeChangeVerifier" style="color: #000000">
              {{ $t('account.setAsVerifier') }}
            </a-checkbox>
          </a-col>
          <a-col :xs="24" :sm="24" :md="24" class="text-right">
            <a-button
              type="primary"
              class="text-center btn-verify-submit"
              htmlType="submit"
            >
              <strong>{{ $t('verify.otpButton') }}</strong>
            </a-button>
          </a-col>
        </a-form>
      </div>
    </div>
    <a-modal
      v-model="isModalOTP"
      :footer="null"
      :centered="true"
      :closable="false"
      :maskClosable="false"
    >
      <div :class="$style.modalOtp">
        <img :src="urlImage" class="logo" :class="$style.logoVendorOtp" />
        <h5 :class="$style.titleInfoOtp">
          {{ user_type === 'phone' ? $t('verify.otpSubTitlePhone') : $t('verify.otpSubTitleEmail')}}
        </h5>
        <div class="mt-2">
          <div class="mb-2 mt-5">
            <div class="d-flex justify-content-center">
              <v-otp-input
                ref="otpInput"
                :input-classes="$style.otpInput"
                separator=""
                :num-inputs="6"
                :should-auto-focus="true"
                :is-input-num="true"
                @on-change="handleOnChangeOTP"
              />
            </div>
          </div>
          <a-button
            type="primary"
            size="large"
            class="text-center btn-verify"
            @click.prevent="handleVerify"
            :disabled="otp.length < 6"
          >
            <strong>{{ $t('verify.otpButton') }}</strong>
          </a-button>

          <p :class="$style.subtitleOtp">
            {{ $t('verify.otpWaitingText') }}
          </p>
          <p :class="$style.timerOTP">
            {{time}}
          </p>
          <a-button
            type="link"
            :disabled="countDown > 0"
            @click.prevent="handleSentOtp(user_type)"
          >
            {{ $t('verify.otpResend') }}
          </a-button>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>

import Cookies from 'js-cookie'
import { mapState } from 'vuex'

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

export default {
  name: 'updateUsername',
  data: function () {
    return {
      form: this.$form.createForm(this),
      countDown: 300,
      loading: false,
      time: '',
      otp: '',
      isModalOTP: false,
      action_type: '',
      user_id: '',
      user_type: '',
      isVerifier: false,
      data_update: '',
    }
  },
  computed: {
    ...mapState(['app']),
    isVendorKino () {
      return this.$store.state.app.vendor_name && this.$store.state.app.vendor_name.toLowerCase() === 'kino'
    },
    urlImage () {
      return this.isVendorKino ? 'https://ik.imagekit.io/powerbiz/img/kino/master_copy.png?updatedAt=1640933165040' : this.$store.state.app.logo
    },
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (err) return
        const payload = {
          userType: this.user_type,
          userId: this.user_id,
          dataUpdate: this.user_type === 'email' ? values.email : values.phone,
          isVerifier: this.isVerifier,
        }
        this.$store.dispatch('user/UPDATEUSERNAME', payload)
          .then((response) => {
            const payload = {
              verifyType: this.user_type,
              user_id: this.user_id,
              type: 'UPDATE_PROFILE',
            }
            this.$store.dispatch('user/SENTOTP', payload)
              .then(() => {
                this.countDown = 300
                this.isModalOTP = true
              })
              .catch(err => {
                this.$notification.error({
                  message: this.$t('authForm.registerFailed'),
                  description: err.response?.data?.message || err.message || 'Internval Server Error',
                })
              })
          })
          .catch(err => {
            this.$notification.error({
              message: this.$t('authForm.registerFailed'),
              description: err.response?.data?.message || err.message || 'Internval Server Error',
            })
          })
      })
    },
    handleCancel() {
      this.previewVisible = false
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    hanldeChangeVerifier (e) {
      this.isVerifier = e.target.checked
    },
    handleSentOtp(username) {
      this.verifyType = username
      this.isModalOTP = true
      const payload = {
        verifyType: this.user_type,
        user_id: this.user_id,
        type: 'UPDATE_PROFILE',
      }
      this.$store.dispatch('user/SENTOTP', payload)
        .then(() => {
          this.countDown = 300
        })
        .catch(err => {
          this.$notification.error({
            message: this.$t('authForm.registerFailed'),
            description: err.response?.data?.message || err.message || 'Internval Server Error',
          })
        })
    },
    handleOnChangeOTP(value) {
      this.otp = value
    },
    handleVerify() {
      const payload = {
        verifyType: this.user_type,
        user_id: this.user_id,
        otp: this.otp,
      }
      this.$store.dispatch('user/VERIFYOTP', payload)
        .then((response) => {
          Cookies.remove('username_edit_data')
          this.$router.push('/account/profile')
        })
        .catch(err => {
          this.$notification.error({
            message: this.$t('authForm.registerFailed'),
            description: err.response?.data?.message || err.message || 'Internval Server Error',
          })
        })
    },
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1

          let minutes = Math.floor(this.countDown / 60)
          minutes = minutes < 10 ? `0${minutes}` : minutes
          let seconds = this.countDown - minutes * 60
          seconds = seconds < 10 ? `0${seconds}` : seconds
          this.time = `${minutes} : ${seconds}`

          this.countDownTimer()
        }, 1000)
      }
    },
  },
  created() {
    this.countDownTimer()
    const data = Cookies.get('username_edit_data') ? JSON.parse(Cookies.get('username_edit_data')) : {}
    this.action_type = data.action_type
    this.user_id = data.user_id
    this.user_type = data.user_type
  },
}
</script>
<style lang="scss" module>
@import "@/components/Profile/style.module.scss";
</style>
<style lang="scss">
.user_detail {
  &-icon {
    background: #fff !important;
    padding: 6px 11px;
    border-radius: 50%;
  }
}
.header {
  position: absolute;
  width: 62px;
  height: 26px;
  left: 457px;
  top: 166px;

  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  /* identical to box height */

  letter-spacing: 0.05em;

  color: #000000;
}
.name {
  /* Subtitle/Medium */

  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
}
.namewithaddress {
  /* Subtitle/Medium */

  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: black;
}
.id {
  /* Body/SM/Regular */

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
}
.verified {
  color: #015CA1;
  border: 1px solid #015CA1;
  background: #E8F6FE;
  border-radius: 12px;
  padding: 4px 6px;
  margin-left: 10px;
  font-size: 12px;
}

.unverified {
  color: #FF0000;
  border: 1px solid #FF0000;
  background: #FFE0E0;
  border-radius: 12px;
  padding: 4px 6px;
  margin-left: 10px;
  font-size: 12px;
}

.account-section {
  display: flex;
}
.username-section {
  min-width: 350px;
}
.title-modal {
  text-align: center;
  font-size: 20px;
  color: #000;
}

.modal-otp {
  text-align: center;
}

.title-modal-otp {
  color: #000000;
  padding-bottom: 15px;
}

.otp-input {
  width: 57px;
  padding: 0;
  text-align: center;
  margin: 0 3px;
  border: 0;
  border-bottom: 3px solid #D2D2D2;
  font-size: 61px;
  color: #000000;
  &.error {
    border: 1px solid red !important;
  }
  &:focus,
  &.focus{
    border-color: #FF8300;
    outline: none;
    box-shadow: none;
  }
}
.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.timer-otp {
  color: #FF8300;
  font-size: 16px;
}
.subtitle-otp {
  color: #000000;
  font-size: 16px;
  margin-top: 8px;
}
.btn-verify {
  width: 80%;
  margin: 36px 0 24px 0;
}
.btn-verify-submit {
  width: 150px;
  margin: 36px 0 24px 0;
}

// New style UI
.section-card {
  margin: 26px 70px;
}
.btn-edit {
  width: 110px;
  background: #E8F6FE;
  border: 1px solid #015289;
  color: #015289;
  border-radius: 8px;
  padding: 7px 0;
  font-size: 14px;
  &:hover {
    background: #cfeafa;
    color: #015289;
  }
}
.btn-view {
  width: 110px;
  background: #D7F6DA;
  border: 1px solid #2CBD12;
  color: #2CBD12;
  border-radius: 8px;
  padding: 7px 0;
  font-size: 14px;
  &:hover {
    background: #c3f6c8;
    color: #2CBD12;
  }
}
.btn-add {
  width: 110px;
  background: #015289;
  border: 1px solid #015289;
  color: white;
  border-radius: 8px;
  padding: 7px 0;
  font-size: 14px;
  &:hover {
    background: #1b78b8;
    border: 1px solid #1b78b8;
    color: white;
  }
}
.verifier {
  color: #F5831F;
  border: 1px solid #F5831F;
  background: #FFF2E6;
  border-radius: 12px;
  padding: 4px 6px;
  margin-left: 10px;
}
</style>
